<template>
  <div class="gradient_home d-flex justify-center">
    <img class="gradient_image" src="/img/static/contact-us.jpg" alt="" />
    <div class="container px-10"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
* {
  transition: all ease 0.2s;
}

.box {
  box-sizing: border-box;
  width: 230px;
  height: 230px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #50575e;
  background-color: hsla(0, 0%, 100%, 0.03);
  text-align: center;
  text-decoration: none;
}

.box:hover {
  border-color: rgba(255, 255, 255, 0.25);
  background-color: rgba(255, 255, 255, 0.05);
}

.logo {
  max-width: 200px;
}

.icon_home {
  max-width: 50px;
}

.container {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  padding-bottom: 150px;
  padding-top: 150px;
  z-index: 3;
}

.gradient_home {
  position: relative;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  object-fit: cover;
}

.gradient_image {
  position: absolute;
  filter: brightness(50%);
  left: 0;
  top: 0;
  width: 100%;

  min-width: 100%;
  min-height: 100%;

  z-index: -1;
}
</style>
