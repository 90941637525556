<template>
  <div class="container center d-col">
    <div class="font-28 text-center main_container">
      {{ $t("home.plans.title") }}
    </div>
    <div class="main_container">
      <div class="info_item item-00">
        <div class="w100 d-flex justify-end highlight">
          {{ $t("home.plans.item_user") }}
          <div class="highlighter"></div>
        </div>
        <div class="info_title">{{ $t("home.plans.item_free") }}</div>
        <div class="info_body">{{ $t("home.plans.item_trial") }}</div>
        <div class="outlineinfo"></div>
        <div class="outlineinfo" v-for="item in items2" :key="item.idx">
          {{ item.perk }}
        </div>
        <a href="https://cloud.securmeet.com/register" target="_self" class="link">{{
          $t("home.plans.getStarted")
        }}</a>
      </div>

      <div class="info_item item-11">
        <div class="w100 d-flex justify-end highlight">
          {{ $t("home.plans.item_plans") }}
          <div class="highlighter"></div>
        </div>
        <div class="info_title">{{ price }} {{ currency }}</div>
        <div class="info_body">{{ $t("home.plans.item_roomMonth") }}</div>
        <div class="outlineinfo"></div>
        <div class="outlineinfo" v-for="item in items" :key="item.idx">
          {{ item.perk }}
        </div>
        <a href="https://cloud.securmeet.com/register" target="_self" class="link">{{
          $t("home.plans.getStarted")
        }}</a>
      </div>

      <div class="info_item item-22">
        <div class="w100 d-flex justify-end highlight">
          {{ $t("home.plans.item_solutions") }}
          <div class="highlighter"></div>
        </div>
        <div class="info_title">{{ $t("home.plans.item_contact") }}</div>
        <div class="info_body">{{ $t("home.plans.item_perMonth") }}</div>
        <div class="outlineinfo"></div>
        <div class="outlineinfo" v-for="item in items" :key="item.idx">
          {{ item.perk }}
        </div>
        <router-link to='/enterprise'  class="link">
          {{ $t("home.plans.getStarted") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { mapState } from "vuex";
export default {
  computed: {
    currency() {
      return this.location.currency;
    },
    price() {
      return process.env.VUE_APP_PRICE;
    },
    items() {
      return [
        { perk: this.$t("home.plans.perk11") },
        { perk: this.$t("home.plans.perk12") },
        { perk: this.$t("home.plans.perk13") },
        { perk: this.$t("home.plans.perk14") },
        { perk: this.$t("home.plans.perk15") },
        { perk: this.$t("home.plans.perk16") },
        { perk: this.$t("home.plans.perk27") },
      ];
    },
    items2() {
      return [
        { perk: this.$t("home.plans.perk21") },
        { perk: this.$t("home.plans.perk22") },
        { perk: this.$t("home.plans.perk23") },
        { perk: this.$t("home.plans.perk24") },
        { perk: this.$t("home.plans.perk25") },
        { perk: this.$t("home.plans.perk26") },
      ];
    },
    ...mapState("user", ["location"]),
  },
  mounted() {
    gsap.from(".item-00", {
      scrollTrigger: ".item-00",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.2,
      ease: "power1.out",
    });
    gsap.from(".item-11", {
      scrollTrigger: ".item-11",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.3,
      ease: "power1.out",
    });
    gsap.from(".item-22", {
      scrollTrigger: ".item-22",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.4,
      ease: "power1.out",
    });
  },
};
</script>

<style scoped lang="less">
* {
  transition: all ease 0.2s;
}

.outlineinfo {
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
}

.container {
  background-color: var(--white);
  color: var(--almost-black);
  padding-top: 50px;
}

.highlight {
  line-height: 40px;
}

.highlighter {
  margin-left: 20px;
  border-right: 2px solid var(--green);
  line-height: 40px;
}

.main_container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 72px;
  padding-top: 40px;
  grid-column-gap: 20px;
}

.info_logo {
  max-width: 50px;
}

.info_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  border: 1px solid #ddd;
  padding-top: 20px;
  padding-bottom: 50px;
}

.info_title {
  font-size: 38px;
  line-height: normal;
  margin-top: 36px;
  margin-bottom: 10px;
  color: var(--almost-black);
}

.info_body {
  font-size: 13px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;
  color: #616161;
}

.link {
  margin-top: 40px;

  background-color: #409875;
  font-size: 14px;
  color: white;
  padding: 10px;

  padding-left: 20px;
  padding-right: 20px;

  outline: none;
}

.link:hover {
  background-color: #56bc93;
}

@media only screen and (max-width: 740px) {
  .main_container {
    flex-direction: column;
  }

  .info_item2 {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
