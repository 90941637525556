<template>
  <div class="container">
    <div class="main_container center w100">
      <div class="grow center box">
        <div class="grow py-30 description">
          <div class="font-28">{{ $t("home.desription.title") }}</div>
          <p class="opacity-60">
            {{ $t("home.desription.subtitle1") }}
          </p>
          <p class="py-30">
            {{ $t("home.desription.subtitle2") }}
          </p>
          <div>
            <router-link class="link" to='whitepaper'>
                 {{ $t("home.desription.learnMore") }}
            </router-link>
          </div>
        </div>
        <div class="grow image_container">
          <img class="image" src="img/static/domenico-loia-364221.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  mounted() {
    gsap.from(".description", {
      scrollTrigger: ".description",
      x: 50,
      y: 0,
      delay: 0.2,
      opacity: 0,
      duration: 1,
    });
  },
};
</script>

<style scoped>
* {
  transition: all ease 0.2s;
}

.container {
  background-color: white;
  color: var(--almost-black);
  overflow: hidden;
}

.box {
  grid-column-gap: 80px;
}

.image {
  max-width: 100%;
  height: auto;
}

.link {
  margin-top: 30px;
  height: 40px;
  background-color: #409875;
  font-size: 14px;
  color: white;
  padding: 10px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;

  outline: none;
}

.link:hover {
  background-color: #56bc93;
}

@media only screen and (max-width: 795px) {
  .box {
    flex-direction: column;
    flex-direction: column-reverse;
  }
}
</style>